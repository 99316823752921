@import '../../styles/variables';

.loader-wrapper-fixed {
  font-family: $sitefont;
  font-weight: bold;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.loader-wrapper {
  font-family: $sitefont;
  font-weight: bold;
  text-align: center;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  background-color: #ffffffff;
  z-index: 9999;
}

.lds-ring {
  width: 60px;
  height: 60px;
}

.lds-text {
  width: 80%;
  margin-top: 30px;
  color: $color-loader;
}

.lds-text-down {
  width: 80%;
  margin-top: 30px;
  color: $color-loader;
  font-weight: normal;
  font-size: $font-size-cta;
}

.lds-text-dark {
  color: $color-background;
}

.lds-ring > div > div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 0;
  border: 8px solid $color-loader;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $color-loader transparent transparent transparent;
}

.lds-ring-dark > div > div {
  border: 8px solid $color-background;
  border-color: $color-background transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-bottom {
  display: flex;
  flex-direction: column;
  position: fixed;
  justify-items: center;
  // height: $cta-height * 3;
  // bottom: $grid-gutter-width / 2 !important;
  bottom: 45px !important;
  left: 0;
  right: 0;

  .lds-text,
  .lds-text-down {
    width: 100%;
    //position: relative !important;
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: $grid-gutter-width / 2 !important;
  }

  .w-button-bottom,
  .w-button-bottom:hover,
  .w-button-bottom:visited,
  .w-button-bottom:active,
  .w-button-bottom:focus {
    bottom: 0;
    position: relative !important;
    margin-bottom: 0 !important;
  }
}
