@import '../../styles/variables';

$text-color: white;
$glow-color-end: #ff2c81;
$animation-duration: 0.5s;
$animation-duration-short: 0.5s;
$animation-delta-px-high: 10px;
$animation-delta-px: 10px;

@keyframes glow {
  from {
    text-shadow: 0 0 5px #00000000, 0 0 10px #00000000, 0 0 15px #00000000;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px $glow-color-end,
      0 0 15px $glow-color-end;
  }
}

#tuto {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9000;

  .tuto-checkbox-text {
    color: white;
    font-size: 20px;
    font-weight: bold;
  }
}

#tuto-bg {
  position: absolute;
  top: 0;
  left: 0;
  background-color: $kadow-text;
  height: 100%;
  width: 100%;
}

#tuto-fg {
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.tuto-title {
  color: white;
  font-size: 35px;
  font-weight: bold;
  padding: 0 $grid-gutter-width / 2;
  -webkit-animation: glow $animation-duration ease-in-out infinite alternate;
  -moz-animation: glow $animation-duration ease-in-out infinite alternate;
  animation: glow $animation-duration ease-in-out infinite alternate;
}

.tuto-text {
  color: white;
  font-size: 20px;
  font-weight: bold;
  padding: 0 $grid-gutter-width / 2;
}

.ios-arrow-ct {
  position: absolute;
  top: 0;
  left: 0;
  padding: $grid-gutter-width / 2;
}

@keyframes hand_high {
  from {
    top: 30px;
    left: 20px;
  }
  to {
    top: calc(30px + #{$animation-delta-px-high});
    left: calc(20px + #{$animation-delta-px-high});
  }
}

.tuto-hand-high {
  position: absolute;
  height: 10vh;
  top: 50px;
  left: 50px;
  -webkit-animation: hand_high $animation-duration-short ease-in-out infinite
    alternate;
  -moz-animation: hand_high $animation-duration-short ease-in-out infinite
    alternate;
  animation: hand_high $animation-duration-short ease-in-out infinite alternate;
}

.ios-tab-img-ct {
  //position: absolute;
  //right: 0;
  //bottom: 0;
  margin: $grid-gutter-width / 2 0;

  img {
    height: 8vh;
  }
}

.tuto-img-ios-google {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  height: 30px;
  margin: 0 7px;
}

@keyframes hand_low {
  from {
    bottom: 50px;
    right: 50px;
  }
  to {
    bottom: calc(50px + #{$animation-delta-px});
    right: calc(50px + #{$animation-delta-px});
  }
}

.tuto-hand-low {
  position: absolute;
  height: 10vh;
  bottom: 50px;
  right: 50px;
  -webkit-animation: hand_low $animation-duration-short ease-in-out infinite
    alternate;
  -moz-animation: hand_low $animation-duration-short ease-in-out infinite
    alternate;
  animation: hand_low $animation-duration-short ease-in-out infinite alternate;
}

.tuto-cta-ct {
  width: 80%;
  height: $cta-height;
  opacity: 0;
}

@keyframes cta_fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

.tuto-cta-fadein {
  -webkit-animation: cta_fadein $animation-duration-short ease-in forwards;
  -moz-animation: cta_fadein $animation-duration-short ease-in forwards;
  animation: cta_fadein $animation-duration-short ease-in forwards;
}

.tuto-sep {
  height: 10vh;
}

@keyframes ios_bck_arrow_anim {
  from {
    color: $color-cdm-3;
    transform: scale(1.5) translateX(5px) translateY(5px);
  }
  to {
    color: white;
    transform: scale(1) translateX(0) translateY(0);
  }
}

.tuto-status-bar {
  display: flex;
  justify-content: flex-start;
  padding-top: 5px;
  padding-left: 5px;
  font-size: 15px;
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  color: white;

  .tuto-ios-bck-arrow {
    color: white;
  }
}
