.box-root {
  display: flex;
  position: relative;
  //z-index: 2;
  flex-direction: column;
  align-items: center;

  .box {
    position: absolute;
    bottom: 5px;
  }

  .box-shadow {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 8px;
    transform: rotateX(45deg) scale(1, 0.2);
    background: radial-gradient(circle, black 0%, rgba(255, 255, 255, 0) 50%);
  }

  .gift {
    text-align: center;
  }

  .imgGift {
    height: 2em;
    width: 2em;
  }
}
