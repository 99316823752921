@import './variables.scss';
@import './functions.scss';

.root {
  margin: 0 auto;
  height: 100%;
  background-color: $color-paua;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.ball {
  position: absolute;
  left: 100px;
  width: 9.1%;
  background-color: $color-ruby;
  border-radius: 50%;
  box-shadow: inset 10px -13px 13px rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(
    240deg,
    rgba(254, 217, 228, 0.4) 0%,
    transparent 100%
  );
  transform: translate(-50%, -50%);
  z-index: 10;
  aspect-ratio: 1/1;
}

.mesh {
  position: relative;
  display: grid;
  grid-gap: 0;
  justify-items: center;
  align-items: center;
}

.mesh_grid_normal {
  grid-template: repeat(8, 1fr) / repeat(11, minmax(9%, 30px));
}

.mesh_grid_16_9 {
  grid-template: repeat(8, 1fr) / repeat(11, minmax(9%, 30px));
}

.pin {
  width: 36%;
  height: 36%;
  background-color: var(--fakir-pin);
  border-radius: 50%;
  filter: drop-shadow(3px 7px 3px rgba(0, 0, 0, 0.659));
}

.cell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  aspect-ratio: 1 / 1;

  &--1 {
    grid-area: 1/2/1/2;
  }

  &--2 {
    grid-area: 1/4/1/4;
  }

  &--3 {
    grid-area: 1/6/1/6;
  }

  &--4 {
    grid-area: 1/8/1/8;
  }

  &--5 {
    grid-area: 1/10/1/10;
  }

  &--6 {
    grid-area: 2/1/2/1;
  }

  &--7 {
    grid-area: 2/3/2/3;
  }

  &--8 {
    grid-area: 2/5/2/5;
  }

  &--9 {
    grid-area: 2/7/2/7;
  }

  &--10 {
    grid-area: 2/9/2/9;
  }

  &--11 {
    grid-area: 2/11/2/11;
  }

  &--12 {
    grid-area: 3/2/3/2;
  }

  &--13 {
    grid-area: 3/4/3/4;
  }

  &--14 {
    grid-area: 3/6/3/6;
  }

  &--15 {
    grid-area: 3/8/3/8;
  }

  &--16 {
    grid-area: 3/10/3/10;
  }

  &--17 {
    grid-area: 4/1/4/1;
  }

  &--18 {
    grid-area: 4/3/4/3;
  }

  &--19 {
    grid-area: 4/5/4/5;
  }

  &--20 {
    grid-area: 4/7/4/7;
  }

  &--21 {
    grid-area: 4/9/4/9;
  }

  &--22 {
    grid-area: 4/11/4/11;
  }

  &--23 {
    grid-area: 5/2/5/2;
  }

  &--24 {
    grid-area: 5/4/5/4;
  }

  &--25 {
    grid-area: 5/6/5/6;
  }

  &--26 {
    grid-area: 5/8/5/8;
  }

  &--27 {
    grid-area: 5/10/5/10;
  }

  &--28 {
    grid-area: 6/1/6/1;
  }

  &--29 {
    grid-area: 6/3/6/3;
  }

  &--30 {
    grid-area: 6/5/6/5;
  }

  &--31 {
    grid-area: 6/7/6/7;
  }

  &--32 {
    grid-area: 6/9/6/9;
  }

  &--33 {
    grid-area: 6/11/6/11;
  }

  &--34 {
    grid-area: 7/2/7/2;
  }

  &--35 {
    grid-area: 7/4/7/4;
  }

  &--36 {
    grid-area: 7/6/7/6;
  }

  &--37 {
    grid-area: 7/8/7/8;
  }

  &--38 {
    grid-area: 7/10/7/10;
  }

  &--39 {
    grid-area: 8/1/8/1;
  }

  &--40 {
    grid-area: 8/3/8/3;
  }

  &--41 {
    grid-area: 8/5/8/5;
  }

  &--42 {
    grid-area: 8/7/8/7;
  }

  &--43 {
    grid-area: 8/9/8/9;
  }

  &--44 {
    grid-area: 8/11/8/11;
  }
}

.range {
  position: absolute;
  width: 80%;
}

.line {
  position: relative;
  width: 100%;
  height: 3px;
  background-color: var(--fakir-line-color);

  &::after {
    content: '';
    position: absolute;
    right: -15px;
    top: 50%;
    border: 10px solid transparent;
    border-left: 17px solid var(--fakir-line-color);
    transform: translateY(-50%);
  }

  &::before {
    content: '';
    position: absolute;
    left: -15px;
    top: 50%;
    border: 10px solid transparent;
    border-right: 17px solid var(--fakir-line-color);
    transform: translateY(-50%);
  }
}

.slots {
  top: 100%;
  width: 100%;
}

.wrapper {
  display: grid;
  grid-template: repeat(2, 1fr) / repeat(11, 1fr);
}

.hole {
  position: relative;
  top: 0;
  width: 100%;
  aspect-ratio: 2 / 1;

  &--1 {
    grid-area: 1/2/1/2;
  }

  &--2 {
    grid-area: 1/4/1/4;
  }

  &--3 {
    grid-area: 1/6/1/6;
  }

  &--4 {
    grid-area: 1/8/1/8;
  }

  &--5 {
    grid-area: 1/10/1/10;
  }
}

.gift {
  position: relative;

  & > img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: rem(30);
    height: rem(30);
    transform: translate(-50%, -50%);
  }

  &--1 {
    grid-area: 2/2/2/2;
  }

  &--2 {
    grid-area: 2/4/2/4;
  }

  &--3 {
    grid-area: 2/6/2/6;
  }

  &--4 {
    grid-area: 2/8/2/8;
  }

  &--5 {
    grid-area: 2/10/2/10;
  }
}