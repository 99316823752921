@import '../../styles/variables';

@keyframes scratch-card-light-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

$color-garland-blue: #44b6e5;
$color-garland-yellow: #ffe49d;
$color-garland-red: #ff9491;

$garland-box-shadow: 0 0 10px 6px;
$garland-box-inset: 0 2px 10px 5px white;
$garland-animation: scratch-card-light-animation 2s linear 2s infinite alternate;

.h1-scratchcard {
  color: black;
  text-align: center;
  font-size: 2.5vh;
  font-weight: bold;
  margin-top: $grid-gutter-width / 2;
}

.sc-garland {
  width: 23px;
  height: 23px;
  border-radius: 100%;
  position: absolute;
  top: 0;
}

.ratio_16_9 {
  .sc-garland {
    width: 18px;
    height: 18px;
  }
}

.sc-light-blue {
  background-color: $color-garland-blue;
}

.sc-light-yellow {
  background-color: $color-garland-yellow;
}

.sc-light-red {
  background-color: $color-garland-red;
}

.sc-light-anim-blue {
  box-shadow: $garland-box-shadow $color-garland-blue, inset $garland-box-inset;
  animation: $garland-animation;
  opacity: 0;
}

.sc-light-anim-yellow {
  box-shadow: $garland-box-shadow $color-garland-yellow,
  inset $garland-box-inset;
  animation: $garland-animation;
  opacity: 0;
}

.sc-light-anim-red {
  box-shadow: $garland-box-shadow $color-garland-red, inset $garland-box-inset;
  animation: $garland-animation;
  opacity: 0;
}

.sc-prize-label-container {
  color: black !important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .sc-prize-label {
    color: black;
    text-align: center;
    overflow-wrap: break-word;
    font-weight: bold;
    width: 90%;
  }

  .sc-prize-blur {
    filter: blur(0.45rem);
  }
}

.sc-christmas-prize-label-container {
  left: 10%;
  top: 46%;
  width: 80%;
  height: 25%;
}

.sc-bg-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.sc-bg-container,
.sc-fg-container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.sc-bg-container {
  z-index: 950;
}

.sc-fg-container {
  z-index: 990;
}

.sc-4 {
  column-gap: 15px;
}