@import '../../styles/variables';

.field-radio-group {
  display: flex;
  flex-wrap: wrap;

  .radio {
    margin-right: 15px;

    label {
      span {
        span {
          margin-left: 5px;
        }
      }
    }
  }
}

.rjsf {
  .control-label {
    text-decoration: underline;
  }

  .form-check-label {
    color: $kadow-text;
  }
}

.field-boolean {
  input[type='checkbox'] {
    margin-right: 5px;
  }
}

.errors {
  margin-bottom: $padding-standard;

  .panel-heading {
    display: none;
  }
}

.error-detail {
  display: none;
}

.k-customform-errors {
  color: red;
}

#root__description {
  margin-bottom: 12px !important;
}