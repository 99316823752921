@import '../../../styles/variables';

.slotmachine-container {
  $gradient-color-component: 120;
  $gradient-color-start: rgba(
    $gradient-color-component,
    $gradient-color-component,
    $gradient-color-component,
    0.7
  );
  $gradient-color-end: rgba(
    $gradient-color-component,
    $gradient-color-component,
    $gradient-color-component,
    0
  );

  display: flex;
  flex: 1;

  flex-direction: column;
  // justify-content: center;
  align-items: center;
  //padding-bottom: 30px;

  // gap: 50px;

  .title {
    font-weight: bold;
    font-size: 20px;
  }

  $sm-border-radius: 10px;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  .spinner-container-container {
    position: relative;
    border: 2px solid black;
    background-color: #ccccc4;

    .sm-separator {
      width: 2px;
      background-color: black;
    }

    .gradient-fade {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      background: linear-gradient(
        to bottom,
        $gradient-color-start 0%,
        $gradient-color-end 15%,
        $gradient-color-end 85%,
        $gradient-color-start 100%
      );
      border-radius: $sm-border-radius;
    }

    .spinner-container {
      display: flex;
      overflow: hidden;

      &.icons:nth-child(2) {
        margin: 0 10px;
      }

      .spinner-margin-right {
        margin-right: 3px;
      }

      .spinner-margin-left {
        margin-left: 3px;
      }

      .spinner-item {
        height: 100%;
        border-radius: $sm-border-radius;

        &.icons {
          display: inline-block;
          overflow: hidden;
          will-change: backgroundPosition;
          transition: 0.3s background-position linear;
          transform: translateZ(0);
        }

        &::after {
          position: absolute;
          content: '';
          display: block;
          width: 100%;
          height: 33%;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  /*
  .w-button-bottom {
    position: relative;
    margin-bottom: 0;
  }
  */
}
