$factor: 2;
$rotateX: 8deg * $factor;
$rotateY: 8deg * $factor;
$translateY: 5px * $factor;

@keyframes flip {
  0% {
    transform: translateX(0) rotateY(0);
  }
  50% {
    transform: translateY($translateY) rotateX($rotateX) rotateY($rotateY);
  }
  100% {
    transform: translateX(0) rotateY(0);
  }
}

.foliage {
  path:not(.cls-wf-1) {
    animation: flip 3s ease-in-out infinite;
  }
}
