@import '../../styles/variables';

.h1-advent-calendar {
  color: black;
  text-align: center;
  font-size: 2.5vh;
  font-weight: bold;
  margin-top: $grid-gutter-width / 2;
}

$animation-duration: 0.5s;

@keyframes blink_advent {
  from {
    opacity: 1;
    zoom: 120%;
  }
  to {
    opacity: 0;
    zoom: normal;
  }
}

.advent-blink {
  -webkit-animation: blink_advent $animation-duration ease-in-out infinite
    alternate;
  -moz-animation: blink_advent $animation-duration ease-in-out infinite
    alternate;
  animation: blink_advent $animation-duration ease-in-out infinite alternate;
}