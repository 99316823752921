@import '../../../styles/variables';
.slotContainerContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center; }


.slotContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;

  .slot {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28%;
    height: 85%;
    margin: 0 10px;
    font-size: 24px;
    overflow: hidden;
    background: white;
    z-index: 2; }

  .slot:nth-of-type(1) {
    left: 3%; }

  .slot:nth-of-type(2) {
    left: 33%; }

  .slot:nth-of-type(3) {
    left: 62%; }

  .slotMachine {
    width: 100%;
    z-index: 3; }

  .foreground {
    position: absolute;
    width: 110%;
    z-index: 4;
    top: 95%;
    pointer-events: none; } }

.background {
  position: absolute;
  width: 120%;
  z-index: 1;
  pointer-events: none; }
