@import 'variables';

@font-face {
  font-family: 'Brown';
  font-display: fallback;
  src: url('https://s3.eu-west-3.amazonaws.com/cadeaudelamaison.com/fonts/Brown-Thin.woff');
  font-weight: $font-weight-light;
}

@font-face {
  font-family: 'Brown';
  font-display: fallback;
  src: url('https://s3.eu-west-3.amazonaws.com/cadeaudelamaison.com/fonts/Brown-Regular.woff');
  font-weight: $font-weight-normal;
}

@font-face {
  font-family: 'Brown';
  font-display: fallback;
  src: url('https://s3.eu-west-3.amazonaws.com/cadeaudelamaison.com/fonts/Brown-Bold.woff');
  font-weight: bold;
}

//@font-face {
//  font-family: "Neutra Display Titling";
//  src: url("//db.onlinewebfonts.com/t/073b787187c81c2e1412805663a10b8b.eot");
//  src: url("//db.onlinewebfonts.com/t/073b787187c81c2e1412805663a10b8b.eot?#iefix") format("embedded-opentype"),
//  url("//db.onlinewebfonts.com/t/073b787187c81c2e1412805663a10b8b.woff2") format("woff2"),
//  url("//db.onlinewebfonts.com/t/073b787187c81c2e1412805663a10b8b.woff") format("woff"),
//  url("//db.onlinewebfonts.com/t/073b787187c81c2e1412805663a10b8b.ttf") format("truetype"),
//  url("//db.onlinewebfonts.com/t/073b787187c81c2e1412805663a10b8b.svg#Neutra Display Titling") format("svg");
//  font-weight: $font-weight-normal;
//}

@font-face {
  font-family: 'NeutraDispTitling';
  font-display: fallback;
  src: url('https://s3.eu-west-3.amazonaws.com/cadeaudelamaison.com/fonts/neutra_display_titling.woff');
  font-weight: $font-weight-normal;
}

@font-face {
  font-family: 'Yellowtail';
  font-display: fallback;
  src: url('https://s3.eu-west-3.amazonaws.com/cadeaudelamaison.com/fonts/yellowtail-v18-latin-regular.woff');
  font-weight: $font-weight-normal;
}
