@import '../../styles/variables';

$glm-border-radius: 10px;

.k-glm-intro-bottom {
  border-radius: $glm-border-radius;
  margin: $padding-standard;
  position: relative;

  .k-glm-intro-bottom-text {
    //color: white !important;
    text-transform: uppercase;
    font-size: 22px !important;
    position: absolute;
    top: 20px;
    width: 80%;
    left: 10%;
    font-weight: bold;
    display: block;
    text-align: center;
    color: white !important;
  }

  .k-glm-intro-bottom-btn {
    position: absolute;
    bottom: 20px;
    width: 80%;
    left: 10%;
  }
}

.k-glm-prize-label-container {
  color: black !important;
  position: absolute;

  top: 0;
  left: 5%;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 90%;
  height: 100%;
}

.k-glm-scratch-text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  color: white;
  font-size: 24px;
  text-transform: uppercase;
  pointer-events: none;
}

$gradient-color-start: #00000055;
$gradient-color-end: #20202000;

.k-glm-gradient {
  background: linear-gradient(
    to bottom,
    $gradient-color-start 0%,
    $gradient-color-end 100%
  );
  height: 100px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

#k-ml-title {
  display: block;
  //text-align: center;
}

.k-glm-text {
  text-align: center !important;
}

.k-glm-optin {
  position: relative;
}

.k-glm-intro-bottom-img {
  border-radius: $glm-border-radius;
}

.k-glm-form-wrapper {
  padding: $grid-gutter-width 0;
}

.k-glm-token-optin {
  position: absolute;
  width: 50px;
  height: 50px;
}
