@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '~bootstrap/scss/bootstrap';

$progress-bar-height: 16px;
$good-answer-color: #54e312;


.skill-progress-bar-top {
  width: 100%;
  height: $progress-bar-height;
}

.skill-progress-bar {
  position: fixed;
  bottom: $padding-standard;
  width: calc(100% - #{$grid-gutter-width});
  left: $grid-gutter-width / 2;
}

.skill-progress-bar,
.skill-progress-bar-top {
  .progress {
    position: relative;

    .progressbar {
      background-color: $kadow-text;
    }
  }
}

.progress {
  border-radius: 10px;
}

.progress-bar {
  border-radius: 10px;
  &.bg-danger {
    background-color: red !important;
  }

  &.bg-success {
    background-color: $good-answer-color !important;
  }
}
