.mb-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  position: relative;
  overflow: visible;

  .grid {
    width: 100%;
    flex: 1;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    overflow: visible;
    margin-bottom: 20px;

    &>div {
      overflow: visible;
      svg {
        width: auto;
        height: 100%;
        overflow: visible;
      }
    }

    &>div:nth-child(odd) {
      //border: 1px solid rosybrown;
      //background-color: rosybrown;
    }

    &>div:nth-child(even) {
      //border: 1px solid yellow;
      //background-color: yellow;
    }
  }

  .mb-padding {
    height: 70px;
    width: 100%;
  }

  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity 0.5s ease;
    z-index: -1;
  }
}

.ratio_16_9 {
  .mb-root {
    .grid {
      //width: 80%;
      //margin-top: 0;
    }
  }
}
