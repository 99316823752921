$img-height: 90px;
$padding-standard: 15px;
$baseFontSize: 16;

// Dices sizing
$screen-parts: 4;
// 1.41 it is square root of two. Allow to calculate the size of the dice's edge depending on the diagonal.
$dice-size: #{(100vw / $screen-parts) / 1.41};

// Colors
// ---------------------------------

// Default

$color-default-black: #000000;
$color-default-white: #ffffff;
$color-transparent: rgba(255, 255, 255, 0);

// Project palette
// Use for colors name https://www.htmlcsscolor.com/hex/

$color-deep-koamaru: #3a3b5a; //bg
$color-summer-sky: #44a4d6; //dice-top-side
//$color-mariner: #445c9b; Color from design too SIMILAR to dice body so changed to #41619b //dice-left-side
$color-mariner: #41619b; //dice-left-side
$color-steel-blue: #4670b6; //dice-right-side
$color-governor-bay: #4d57a2; //dice-body 
$color-cranberry: #d45479;
$color-black: #181c26;
//Fakir-game
$color-paua: #29235c;
$color-blackcurrant: #290038;
$color-ruby: #e91c73;


// Viewports
// ---------------------------------

$vp-320: 320px;
$vp-375: 375px;
$vp-768: 768px;
