#logo-notch-container {
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 4;

  #logo-notch {
    width: 60%;
    height: 105px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    display: grid;
    place-items: center;

    img {
      height: 75px;
    }
  }
}

.ratio_16_9 {
  #logo-notch-container {
    #logo-notch {
      width: 50%;
      height: 80px;
      img {
        height: 50px;
      }
    }
  }
}