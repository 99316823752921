@import '../../../styles/variables';

.k-glm-title-1,
.k-glm-title-2 {
  text-align: center;
  font-weight: $font-weight-normal;
}

.k-glm-title-1 {
  font-family: 'NeutraDispTitling', sans-serif !important;
  font-size: 3rem;
  line-height: 2.8rem;
}

.k-glm-title-2 {
  font-family: 'Yellowtail', sans-serif !important;
  font-size: 2.5rem;
  line-height: 2.3rem;
}

.ratio_16_9 {
  .k-glm-title-1 {
    font-size: 2rem;
    line-height: 1.8rem;
  }

  .k-glm-title-2 {
    font-size: 1.7rem;
    line-height: 1.4rem;
  }
}
