@import '../../styles/variables';
@import '~bootstrap/scss/bootstrap';

$image-height: 20px;
$image-margin-right: 15px;

.w-btn-container {
  width: 100%;

  button,
  button:active,
  button:visited,
  button:hover {
    border: none;
    background-color: transparent;
    width: 100%;
    color: unset;
    text-transform: uppercase;
    font-weight: bold;
  }
}

.w-btn-no-width {
  width: auto;
}

.w-button-normal,
.w-button-normal:hover,
.w-button-normal:visited,
.w-button-normal:active,
.w-button:focus {
  color: $color-cta-foreground;
  background-color: $color-cta-background;
  padding: 0 ($grid-gutter-width / 2) !important;
}

.w-button,
.w-button:hover,
.w-button:visited,
.w-button:active,
.w-button:focus {
  text-transform: uppercase;
  text-underline: none;
  font-family: $sitefont;
  font-weight: bold;
  font-size: 16px;
  width: 100%;
  height: $cta-height;
  cursor: pointer;
  border-radius: $border-radius;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  text-decoration: none;

  align-items: center;
  justify-content: center;

  border: none;

  span {
    text-align: center;
    padding-top: 5px;
  }

  span.with-img {
    padding-right: $image-margin-right + $image-height;
  }
}

.w-btn-text-left,
.w-btn-text-left:active,
.w-btn-text-left:hover,
.w-btn-text-left:focus,
.w-btn-text-left:visited {
  justify-content: start;
  padding-left: 0 !important;
  padding-right: 0 !important;

  .w-btn-img {
    margin-left: $image-margin-right;
  }
}

.w-button-bottom,
.w-button-bottom:hover,
.w-button-bottom:visited,
.w-button-bottom:active,
.w-button-bottom:focus {
  position: fixed;

  height: $cta-height;

  left: 0 !important;
  right: 0 !important;

  padding-right: ($grid-gutter-width / 2) !important;
  padding-left: ($grid-gutter-width / 2) !important;
  margin-bottom: $grid-gutter-width * 1.5 !important;
  bottom: 0 !important;

  z-index: 100;
}

@include media-breakpoint-up(md) {
  .w-button-bottom,
  .w-button-bottom:hover,
  .w-button-bottom:visited,
  .w-button-bottom:active,
  .w-button-bottom:focus {
    width: $width-lg !important;
    left: calc((100% - #{$width-lg}) / 2) !important;
  }
}

.w-button-bottom-double {
  height: 2 * $cta-height + 10px;

  .w-button-bottom {
    margin-bottom: 0 !important;
  }
}

.w-button-bottom-triple {
  height: 3 * $cta-height + 10px;

  .w-button-bottom {
    margin-bottom: 0 !important;
  }
}

.w-btn-yellow,
.w-btn-yellow:hover,
.w-btn-yellow:visited,
.w-btn-yellow:active,
.w-btn-yellow:focus {
  background-color: $color-cta-background;
  color: white;
}

.w-btn-img {
  vertical-align: middle;
  height: $image-height;
  width: $image-height;
  object-fit: contain;
  //margin-left: $image-margin-right;
  margin-right: $image-margin-right;
}

.w-btn-fb,
.w-btn-fb:hover,
.w-btn-fb:active,
.w-btn-fb:visited,
.w-btn-fb:focus {
  background-color: $color-facebook-blue !important;
  color: white !important;
  font-size: 16px !important;
  font-family: $sitefont !important;
  font-weight: bold !important;
}

.w-btn-download-app {
  background-color: black !important;

  span {
    color: white !important;
  }
}

.w-btn-snapchat {
  background-color: #fffc00 !important;

  span {
    color: $color-background !important;
  }
}

.w-btn-ig {
  background: linear-gradient(
    120deg,
    rgba(64, 93, 230, 1) 0%,
    rgba(88, 81, 219, 1) 15%,
    rgba(131, 58, 180, 1) 25%,
    rgba(193, 53, 132, 1) 35%,
    rgba(225, 48, 108, 1) 45%,
    rgba(245, 96, 64, 1) 60%,
    rgba(247, 119, 55, 1) 70%,
    rgba(252, 175, 69, 1) 80%,
    rgba(255, 220, 128, 1) 100%
  ) !important;
  color: white !important;
}

.w-btn-tripad,
.w-btn-tripad:active,
.w-btn-tripad:hover,
.w-btn-tripad:visited,
.w-btn-tripad:focus {
  border: 1px solid $kadow-text !important;
  background-color: white !important;
  color: black !important;

  .w-btn-img {
    height: $image-height * 1.5;
    width: $image-height * 1.5;
  }
}

.w-btn-google {
  border: 1px solid #7e7e7e !important;
  background-color: #4285f4 !important;

  span {
    color: white !important;
  }
}

.w-btn-disabled {
  background-color: $color-grey-heavy !important;
  color: $color-cta-disabled-foreground !important;
}

.w-btn-ig.w-btn-disabled {
  background: $color-grey !important;
  color: white !important;
}

.w-btn-white {
  background-color: white !important;

  span {
    color: $kadow-text !important;
  }
}

.w-btn-story-secondary {
  border: 1px solid white !important;
  color: white !important;
  background-color: $color-cdm-5 !important;
}

.w-btn-story-tertiary {
  border: none !important;
  color: $kadow-text !important;
  background-color: transparent !important;
}

.w-btn-left-icon {
  font-size: 22px;
  margin-right: $image-margin-right;
}

.w-btn-badge {
  position: absolute;
  background-color: $color-cdm-5 !important;
  color: white;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  left: 0;
  top: -15px;
  padding-top: 3px;
  font-size: 18px;
}

.w-btn-inverted,
.w-btn-inverted:active,
.w-btn-inverted:focus,
.w-btn-inverted:visited,
.w-btn-inverted:hover {
  border: 1px solid $cta-inverted-fg-color;
  background-color: $cta-inverted-bg-color;
  color: $cta-inverted-fg-color;
}

.w-btn-deliveroo {
  background-color: #00ccbc !important;
  color: white !important;
}

.w-btn-ubereats {
  background-color: black !important;
  color: white !important;
}

.w-btn-fnac {
  background-color: white !important;
  border: 1px solid black !important;
  color: black !important;
}

$cdiscount-color: #e13c13;
// $cdiscount-color: #1d90be;

.w-btn-cdiscount {
  background-color: white !important;
  border: 1px solid $cdiscount-color !important;
  color: $cdiscount-color !important;
}

.w-btn-tiktok,
.w-btn-tiktok:active,
.w-btn-tiktok:focus,
.w-btn-tiktok:visited,
.w-btn-tiktok:hover {
  background-color: black;
  color: #1ff4ee;
}

$color-stpatricks-start: #f75307;
$color-stpatricks-end: #ffffff80;

.w-btn-stpatricks,
.w-btn-stpatricks:active,
.w-btn-stpatricks:focus,
.w-btn-stpatricks:visited,
.w-btn-stpatricks:hover {
  background: linear-gradient(
    0deg,
    $color-stpatricks-start 0%,
    $color-stpatricks-end 100%
  ) !important;
  font-weight: bold !important;
  color: white !important;
}

#w-btn-submit {
  height: $cta-height;
  line-height: $cta-height;
}

.ratio_16_9 {
  .w-button,
  .w-button:hover,
  .w-button:visited,
  .w-button:active,
  .w-button:focus,
  .w-button-bottom,
  .w-button-bottom:hover,
  .w-button-bottom:visited,
  .w-button-bottom:active,
  .w-button-bottom:focus {
    height: $cta-height-16-9;
  }
}