@import '../../styles/variables';
@import '../../styles/fonts';
@import '~bootstrap/scss/bootstrap';

$font-size-quizz-header: 16px;
$font-size-home-text: 24px;
$font-size-home-sub-text: 18px;
$font-size-question-text: 18px;
$progress-bar-height: 16px;

.quizz-header {
  color: $color-font-footer;
  background-color: $color-background-footer;
  width: 100%;
  padding: 5px 15px;
  display: flex;
  height: 35px;
  justify-content: space-between;

  .quizz-header-center {
    width: 100%;
    display: block;
    text-align: center;
  }

  .quizz-header-left,
  .quizz-header-right {
    font-size: $font-size-quizz-header;
    font-weight: bold;
  }
}

#quizz {
  display: flex;
  flex: 1 1;
  flex-direction: column;

  .quizz-main {
    flex-direction: column;
    overflow-y: scroll;
    display: flex;
    flex: 1 1;
  }

  .w-margin-bottom {
    font-size: 12px;

    .all-answer {
      .quizz-answer-container {
        margin-bottom: 2.5px;

        #cta {
          a {
            font-size: 14px !important;

            &.w-button-normal {
              padding: $grid-gutter-width / 4 !important;
              height: unset !important;
            }
          }
        }
      }
    }
  }

  .middle-zone {
    margin-bottom: ($grid-gutter-width / 2);
    display: flex;
    align-items: center;
    justify-content: center;

    .disk-container {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      .audio-controller {
        background-color: rgba(white, 0.7);
        border-radius: 50%;

        i {
          position: relative;
          font-size: 7vh;
        }
      }
    }
  }

  .pic-container-modal {
    margin: 5px 0;
  }

  .quizz-home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .quizz-progress-bar-top {
    width: 100%;
    height: $progress-bar-height;
  }

  .quizz-progress-bar {
    position: fixed;
    bottom: $padding-standard;
    width: calc(100% - #{$grid-gutter-width});
    left: $grid-gutter-width / 2;
  }

  .quizz-progress-bar,
  .quizz-progress-bar-top {
    .progress {
      position: relative;

      .progressbar {
        background-color: $kadow-text;
      }
    }
  }

  .quizz-home-text {
    padding: 0 2 * $padding-standard;
    font-weight: bold;
    text-align: center;
    font-size: $font-size-home-text;
    margin-bottom: $padding-standard;
  }

  .quizz-home-sub-text {
    font-weight: bold;
    text-align: center;
    font-size: $font-size-home-sub-text;
  }

  .q-question-text {
    font-weight: bold;
    font-size: $font-size-question-text;
  }

  .quizz-gift-container {
    margin-bottom: $padding-standard / 2;
    overflow-y: scroll;
  }

  $good-answer-color: #54e312;

  .quizz-btn {
    border: 3px solid transparent;
  }

  .quizz-correct {
    border: 3px solid $good-answer-color;
    border-radius: $border-radius;
  }

  .quizz-good-answer-btn {
    background-color: $good-answer-color !important;
    color: white !important;
  }

  .quizz-good-answer-img {
    border: 2px solid $good-answer-color !important;
    border-radius: 5px;
  }

  .quizz-bad-answer-img {
    border: 2px solid red !important;
    border-radius: 5px;
  }

  .progress {
    border-radius: 10px;
  }

  .progress-bar {
    &.bg-danger {
      background-color: red !important;
    }

    &.bg-success {
      background-color: $good-answer-color !important;
    }
  }

  @keyframes hithere {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  .logo-container {
    width: 100%;
    display: flex;
    justify-content: center;

    .logo-quizz {
      width: auto;
      height: 15vh;
    }
  }

  .logo {
    margin-top: $grid-gutter-width / 2;
    margin-bottom: $grid-gutter-width / 2;
  }

  .logo-lp {
    height: unset !important;
  }
}

.logo-container-centered {
  width: 100%;
  display: flex;
  justify-content: center;
}

@include media-breakpoint-up(md) {
  #quizz {
    width: 100%;

    .quizz-main {
      overflow-y: hidden;
    }

    .quizz-progress-bar {
      width: $width-md;
      left: calc((100% - #{$width-md}) / 2);

      .w-btn-container {
        width: 90% !important;
        margin-left: 5% !important;
      }
    }
  }
}

.animate-answer {
  animation: hithere 0.5s ease 2;
  font-size: 24px;
  font-weight: bold;
}

.ratio_16_9 {

  @include media-breakpoint-down(xs) {
    #quizz {
      .quizz-home-text {
        font-size: 18px !important;
        margin-bottom: 0;
      }
      .quizz-home-sub-text {
        font-size: 14px !important;
        margin-bottom: 0;
      }
    }
  }

  .animate-answer {
    font-size: 18px;
  }
}

@include media-breakpoint-up(lg) {
  #quizz {
    width: 100%;

    .quizz-progress-bar {
      width: $width-lg;
      bottom: $padding-standard;
      left: calc((100% - #{$width-lg}) / 2);
    }

    .q-question-text {
      font-size: $font-size-question-text-16-9;
    }
  }
}