$sitefont: 'Brown', sans-serif !important;

$font-weight-light: 200;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-black: 900;

$font-size-tiny: 10px;
$font-size-small: 12px;
$font-size-medium: 13px;
$font-size-normal: 14px;
$font-size-big: 16px;
$font-size-cta: 18px;

$line-height-normal: 22px;

$font-size-h1: 20px;
$font-size-h2: 26px;
$font-size-h4: 20px;
$font-size: 16px;
$font-size-below-normal: 14px;
$font-size-big: 18px;

$border-radius: 3px;

$kadow-blue: #0e0074;
$kadow-red: #ff2c81;
$kadow-light-blue: #4ffbb1;
$kadow-text: #323232;

$color-cdm-2: #153b4c;
$color-cdm-3: #fcd46d;
$color-cdm-5: #ed7352;
$color-cdlm-turquoise: #4dbbbd;

$color-font: white;
$color-font-popup: $kadow-text;
$color-background: $kadow-blue;
$color-h2: white;
$color-font-footer: white;
$color-background-footer: $kadow-blue;

$color-cta-foreground: white;
$color-cta-background: $kadow-red;

$cta-inverted-fg-color: $kadow-red;
$cta-inverted-bg-color: white;

$color-cta-disabled: $color-cdm-3;
$color-cta-disabled-foreground: $kadow-text;
$color-highlight: $kadow-light-blue;
$color-loader: $kadow-light-blue;

$color-grey: #c2c2c2;
$color-grey-heavy: #7e7e7e;
$color-facebook-blue: #3b5998;
$grid-gutter-width: 30px !default;
$checkbox-container-height: 50px;
$standard-container-width: 375px;
$container-width-desktop-big: $standard-container-width;
$container-width-desktop-small: $standard-container-width;
$container-width-desktop-tablet: $standard-container-width;
$container-width-desktop-mobileonly: 100%;

$color-tripadvisor: #93e9c9;

$cta-height: 55px;
$cta-height-16-9: 45px;
$cta-border-width: 3px;
$cta-offset-height: 0px; // 3px + $cta-border-width;

$padding-standard: 15px;

$cm-fraud-alert-height: 44px;
$cm-bottom-legal-container-height: 30px;

$white-60p: rgba(255, 255, 255, 0.6);
$white-50p: rgba(255, 255, 255, 0.5);
$white-40p: rgba(0, 0, 0, 0.4);
$white-30p: rgba(255, 255, 255, 0.3);
$white-20p: rgba(255, 255, 255, 0.2);
$white-10p: rgba(255, 255, 255, 0.1);
$white-0p: rgba(255, 255, 255, 0);

$width-md: 60%;
$width-modal-md: 60%;

$width-lg: 30%;
$width-modal-lg: 30%;

$font-size-16-9: 14px;
$font-size-titles-16-9: 18px;
$font-size-question-text-16-9: 16px;
