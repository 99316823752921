@import '../../styles/variables';

.giftmachine-container {
  $gradient-color-component: 150;
  $gradient-color-start: rgba(
                  $gradient-color-component,
                  $gradient-color-component,
                  $gradient-color-component,
                  0.7
  );
  $gradient-color-end: rgba(
                  $gradient-color-component,
                  $gradient-color-component,
                  $gradient-color-component,
                  0
  );
  $sm-border-radius: 22px;

  display: flex;
  flex: 1;

  flex-direction: column;
  align-items: center;
  z-index: 10;

  .title {
    font-weight: bold;
    font-size: 20px;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  .spinner-container-container {
    border-radius: $sm-border-radius;

    .gradient-fade, .spinner-container, .spinner-container > .spinner-item {
      border-radius: $sm-border-radius;
    }
  }

  .spinner-container-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .sm-separator {
      width: 2px;
      background-color: black;
    }

    .gradient-fade {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      background: linear-gradient(
                      to bottom,
                      $gradient-color-start 0%,
                      $gradient-color-end 30%,
                      $gradient-color-end 70%,
                      $gradient-color-start 100%
      );
    }

    .spinner-container {
      border-radius: 150px/600px;
      overflow: hidden;

      .spinner-item {
        height: 100%;

        &.icons {
          display: inline-block;
          overflow: hidden;
          will-change: backgroundPosition;
          transition: 0.3s background-position linear;
          transform: translateZ(0);
        }
      }
    }
  }
}

.k-glm-cta-container {
  width: 100%;
  height: $cta-height;
}

.ratio_16_9 {
  $sm-border-radius-16-9: 18px;
  .giftmachine-container {
    .spinner-container-container {
      .spinner-container {
        border-radius: 150px/600px;
        overflow: hidden;
      }
    }
  }
}