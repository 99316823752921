@import '~normalize.css/normalize.css';
@import 'variables';
@import 'fonts';
@import '~bootstrap/scss/bootstrap';

html,
body,
#root,
div,
p,
span,
h1,
h2,
h3,
h4,
h5,
ul,
li {
  font-family: $sitefont;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: white;
}

html,
body {
  -webkit-overflow-scrolling: unset;
}


body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  color: $color-font;
  position: relative;
}

.container {
  padding-left: 0;
  padding-right: 0;
}

.game-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  position: absolute;
}

.lds-text {
  font-size: 28px;
}

h2,
.h2 {
  color: $color-h2;
  font-size: $font-size-h2 !important;
  font-family: $sitefont;
  font-weight: bold !important;
  margin-bottom: 0 !important;
}

.h4 {
  color: $color-h2;
  font-size: $font-size-h4;
  font-family: $sitefont;
  font-weight: bold !important;
  margin-bottom: 0 !important;
}

.highlight {
  color: $color-highlight;
}

h1 {
  color: $color-font;
  font-size: $font-size-h1 !important;
  font-family: $sitefont;
  font-weight: bold !important;
  margin-bottom: $grid-gutter-width;
}

p {
  font-size: $font-size !important;
  font-family: $sitefont;
  color: $color-font;
  margin-bottom: 0 !important;

  a,
  a:hover,
  a:visited,
  a:active {
    color: white;
    text-decoration: underline;
  }
}

.form-control {
  border-radius: $border-radius;
}

.logo {
  height: 44px;
}

.logo-container {
  margin: ($grid-gutter-width / 2) 0;
}

.logo-container-no-margin-bottom {
  margin: ($grid-gutter-width / 2) 0 0;
}

.logo-container-advent {
  margin: ($grid-gutter-width / 2) 0 0 0;
}

.bottom-legal-container {
  position: fixed;
  bottom: 0;

  width: 100%;

  height: $cm-bottom-legal-container-height;
  min-height: $cm-bottom-legal-container-height;
  max-height: $cm-bottom-legal-container-height;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  color: $color-font-footer;
  background-color: $color-background-footer;
  z-index: 1051;

  .bottom-legal-cell {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-size: 13px !important;

    .bottom-legal,
    .bottom-legal:visited,
    .bottom-legal:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.gift-image {
  position: fixed;
  top: 40%;
  right: -170px;
  height: 50%;
  z-index: 0;
}

.app-bottom-shadow {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 45%;
  width: 100%;
  background-image: linear-gradient(180deg, #00000000, #333333c1);
}

.search-box-container {
  > div > div {
    z-index: 100;
  }

  font-family: $sitefont;

  input[type='text']:not(.browser-default),
  input[type='text']:not(.browser-default):focus:not([readonly]) {
    border: none !important;
    font-size: $font-size-big !important;
  }
}

.checkbox {
  tr {
    display: flex;
    align-items: center;
  }

  .checkbox-td {
    position: relative;
    height: 15px !important;
    width: 50px !important;
    padding: 0;
    overflow: hidden;
  }

  .checkbox-table {
    width: 100%;
    cursor: pointer;
    margin: ($grid-gutter-width / 2) 0;
  }

  .checkbox-img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 30px !important;
    width: 15px !important;
  }

  .checkbox-img-checked {
    top: -15px;
  }

  .lite-gray-check {
    background-image: url(http://csscheckbox.com/checkboxes/lite-gray-check.png);
  }
}

.w-optin {
  .checkbox-text {
    text-align: left;
    color: $kadow-text;
    font-size: $font-size-below-normal !important;
    font-weight: normal;
  }
}

.w-optout {
  .checkbox-text {
    text-align: left;
    color: $color-grey-heavy;
    font-size: $font-size-small !important;
    font-weight: normal;
  }
}

.fortune-app-container {
  z-index: 90;
}

.w-margin-top-2-thirds {
  margin-top: ($grid-gutter-width * 0.6) !important;
}

.w-padding-top-2-thirds {
  padding-top: ($grid-gutter-width * 0.6) !important;
}

.w-padding-bottom-2-thirds {
  padding-bottom: ($grid-gutter-width * 0.6) !important;
}

.w-margin-top-2-thirds {
  margin-bottom: ($grid-gutter-width * 0.6) !important;
}

.w-margin-top-half {
  margin-top: 10px !important;
}

.w-padding-top-half {
  padding-top: 10px !important;
}

.w-margin-top {
  margin-top: $grid-gutter-width !important;
}

.w-margin-top-double {
  margin-top: $grid-gutter-width + 10px !important;
}

.w-margin-bottom {
  margin-bottom: $grid-gutter-width !important;
}

.w-margin-bottom-double {
  margin-bottom: $grid-gutter-width * 2 !important;
}

.w-padding-bottom {
  padding-bottom: $grid-gutter-width / 2 !important;
}

.w-margin-bottom-half {
  margin-bottom: $grid-gutter-width / 2 !important;
}

.w-margin-bottom-third {
  margin-bottom: $grid-gutter-width / 3 !important;
}

.input-error {
  border: 3px solid red;
}

.input-number-container {
  input[type='number'] {
    -moz-appearance: textfield;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.w-modal-eula {
  color: $color-background;

  a,
  a:visited,
  a:hover,
  a:active {
    color: $color-background;
  }

  .modal-body {
    padding: $padding-standard;
    overflow-y: scroll;
  }

  h1 {
    font-size: 24px !important;
    font-weight: bold;
  }

  h2 {
    font-size: 18px !important;
    font-weight: bold;
    margin: 15px 0 10px 0;
  }

  p,
  ul {
    margin-bottom: 10px !important;
  }

  ul {
    padding-inline-start: 10px !important;
    li {
      margin-bottom: 10px;
    }
  }
}

.ts-redeem {
  overflow-y: auto;
  overflow-x: hidden;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  &::-webkit-scrollbar {
    width: 0;
    background: transparent; /* Chrome/Safari/Webkit */
  }
}

.w-bottom-line {
  border-bottom: 1px solid white;
}

.cm-fraud-alert {
  height: $cm-fraud-alert-height;
  background-color: red;
  color: white;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  padding: 12px 15px 8px 15px;
  margin-bottom: 10px;
}

.w-tutorial-choice {
  padding: 10px;
  // background-color: $kadow-red;
  // color: white;
  cursor: pointer;
  text-align: center;
  font-weight: bold;

  span,
  a,
  a:visited,
  a:hover,
  a:active,
  a:focus {
    display: block;
    width: 100%;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
  }
}

.ts-help-modal {
  .modal-footer {
    display: block;
  }
}

.ts-blur {
  filter: blur(0.2rem);
}

.cm-pad {
  padding-left: $padding-standard;
  padding-right: $padding-standard;
}

.ts-modal-no-backdrop {
  opacity: 0 !important;
}

a.descLink,
a.descLink:hover,
a.descLink:visited,
a.descLink:active {
  color: white;
  text-decoration: underline;
}

.place-selector-container {
  position: absolute;
  width: 100%;
  bottom: $grid-gutter-width * 1.5;
}

.place-selector-item {
  padding: 20px;
}

.place-option {
  background-color: transparent;
  color: inherit;
  cursor: default;
  display: block;
  padding: 8px 12px;
  width: 100%;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
}

.place-option-selected {
  background-color: #deebff !important;
}

.place-option-subtitle {
  font-size: 12px;
}

.pro-contact-fixed {
  display: block;
  position: fixed;
  width: 100%;
  text-decoration: underline;
  left: 0 !important;
  right: 0 !important;
  bottom: 100px;
  text-align: center;
}

.bottom-start-page {
  position: fixed;
  width: 100%;
  padding: 0 ($grid-gutter-width / 2) !important;
  left: 0;
  right: 0;
  bottom: 40px;
}

.bottom-after-wheel {
  display: flex;
  flex-direction: column;
}

.pic-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pic-container-modal {
  margin: $grid-gutter-width 0;
  padding: 0;
}

.modal-dialog {
  width: auto;
  padding: $grid-gutter-width / 2;

  h2, .h2 {
    font-weight: bold;
  }

  .modal-content {
    max-height: calc(100vh - #{$grid-gutter-width}) !important;

    .modal-header {
      border-bottom: none;
    }

    .modal-body {
      padding: $grid-gutter-width $grid-gutter-width / 2;

      .dummy-div {
        width: 100%;
        height: 0;
      }

      .w-modal {
        display: flex;
        flex-direction: column;

        h1 {
          margin-bottom: $grid-gutter-width / 2;
        }

        h1,
        .h2,
        p,
        ol,
        .control-label {
          color: $color-font-popup;
        }

        ol {
          padding-left: 1.2em;
        }

        .container {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }
    }
  }
}

.modal-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  color: $kadow-text;
}

.ct-coupon-cta-pad {
  height: $cta-height + $grid-gutter-width * 2;
  min-height: $cta-height + $grid-gutter-width * 2;
  max-height: $cta-height + $grid-gutter-width * 2;
}

.ct-coupon-cta {
  position: absolute;
  bottom: $cm-bottom-legal-container-height;
  height: $cta-height + $grid-gutter-width;
  width: 100%;
  padding: 0 ($grid-gutter-width/2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.confetti-canvas {
  display: block;
  z-index: 1800;
  pointer-events: none;
  position: fixed;
  top: 0;
}

.wheel-title-separator {
  height: $grid-gutter-width;
}

$stepper-font-size: 16px;

.w-modal-stepper {
  display: flex;
  flex-direction: column;

  .w-modal-stepper-step {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;

    .w-modal-stepper-step-number {
      display: flex;
      align-items: center;
      justify-content: center;
      justify-items: center;
      align-content: center;

      background-color: $kadow-text;
      color: white;
      font-weight: bold;
      border-radius: 50%;
      margin-right: 10px;
      padding-top: 4px;

      font-size: $stepper-font-size;

      min-width: $stepper-font-size * 2;
      width: $stepper-font-size * 2;
      max-width: $stepper-font-size * 2;

      min-height: $stepper-font-size * 2;
      height: $stepper-font-size * 2;
      max-height: $stepper-font-size * 2;
    }
  }
}

.w-modal-menu,
.w-modal-couponsupportrequest {
  .modal-dialog {
    .modal-body {
      overflow-y: scroll;

      p,
      h2,
      input,
      label {
        color: $kadow-text;
      }

      .k-legal-id {
        font-size: 10px !important;
      }
    }
  }
}

.w-modal-monlogis {
  .modal-dialog {
    padding: 0 !important;
    //margin: 0 8px !important;

    .modal-body {
      overflow-y: scroll;

      .dummy-div {
        width: 100%;
        height: 0;
      }
    }
  }

  .modal-close {
    right: 20px;
    top: 15px;
  }
}

.spin {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*
$grid-breakpoints: (
xs: 0,
sm: 576px,
md: 768px,
lg: 992px,
xl: 1200px
);
 */

@include media-breakpoint-up(md) {
  .game-container {
    width: $width-md;
    overflow: hidden;
    left: (100% - $width-md) / 2;
  }
  .bottom-legal-container {
    width: $width-md;
    left: (100% - $width-md) / 2;
  }
  .modal-lg {
    max-width: $width-modal-md !important;
  }
}

@include media-breakpoint-up(lg) {
  .cm-fraud-alert {
    height: 0 !important;
    display: none !important;
  }
  .game-container,
  .bottom-legal-container {
    width: $width-lg;
    left: (100% - $width-lg) / 2;
  }
  .modal-lg {
    max-width: $width-modal-lg !important;
  }

  .game-container {
    //align-items: center;
    // text-align: center;
  }
}

.modal {
  .modal-body {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.reference {
  font-size: 12px;
}

.ratio_16_9 {

  @include media-breakpoint-down(xs) {
    #quizz {
      font-size: $font-size-16-9;

      h1,
      h2,
      .h1,
      .h2 {
        font-size: $font-size-titles-16-9;
      }

      span,
      p {
        font-size: $font-size-16-9;
      }

      .w-margin-top-double {
        margin-top: 20px !important;
      }

      .w-margin-top {
        margin-top: 12px !important;
      }

      .w-margin-top-half {
        margin-top: 8px !important;
      }

      .w-margin-bottom-double {
        margin-bottom: 20px !important;
      }

      .w-margin-bottom {
        margin-bottom: 12px !important;
      }

      .w-margin-bottom-half {
        margin-bottom: 8px !important;
      }

      .w-optout {
        .checkbox-text {
          font-size: $font-size-16-9 !important;
        }
      }

      .w-optin {
        .checkbox-text {
          font-size: $font-size-16-9 !important;
        }
      }
    }

    .w-modal {
      h1,
      h2 {
        font-size: $font-size-titles-16-9 !important;
      }

      span,
      p, ul, li {
        font-size: $font-size-16-9 !important;
      }

      small {
        font-size: 12px !important;
        span,
        p {
          font-size: 12px !important;
        }
      }
    }
  }
}

.w-voucher-border {
  border: 2px solid $kadow-red;
  padding: 10px;
  text-align: center;
}

.sc-title {
  //white-space: pre;
}

.cta-fg {
  position: absolute;
  bottom: $cm-bottom-legal-container-height;
  z-index: 1000;
}

.redeem {
  overflow-y: scroll;
}

.k-skip {
  color: $kadow-text;
}

.sigCanvas {
  border: 1px solid black;
}

.k-modal-signing {
  .modal-body {
    padding: $padding-standard;
    overflow-y: scroll;
  }
}

form {
  button[type='submit'] {
    outline: transparent none 0;
  }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.fade-in {
  animation: fadeIn 3s;
}

.modal-dialog .modal-content .modal-body {
  h1,
  .h1,
  h2,
  .h2,
  p,
  ol,
  .control-label,
  .form-check-label {
    color: $kadow-blue;
  }
}

@keyframes ghettoblaster {
  25%{
    transform: scaleY(1);
  }
  50%{
    transform: scaleY(1.2);
  }
  75%{
    transform: scaleY(1);
  }
  100%{
    transform: scaleY(1);
  }
}

@keyframes tilt-n-move-shaking {
  0% { transform: translate(0, 0) rotate(0deg); }
  25% { transform: translate(5px, 5px) rotate(5deg); }
  50% { transform: translate(0, 0) rotate(0deg); }
  75% { transform: translate(-5px, 5px) rotate(-5deg); }
  100% { transform: translate(0, 0) rotate(0deg); }
}

a.modal-bottom-link,
a.modal-bottom-link:hover,
a.modal-bottom-link:active,
a.modal-bottom-link:visited {
  display: block;
  color: black;
  text-decoration: underline;
  text-align: center;
}

.modal-dialog .modal-content .modal-body p.big {
  font-size: 16px !important;
  color: red !important;
  font-weight: bold !important;
  border: 1px solid red !important;
  padding: 8px;
}